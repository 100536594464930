import React from 'react';
import { StyledButton } from './Hamburger-style';

export const Hamburger = ({ onClick, color, className }) => {
  return (
    <StyledButton className={'apd-hamburger ' + className} onClick={onClick}>
      <svg
        width='18'
        height='12'
        viewBox='0 0 18 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.508789 2V0H18V2H0.508789ZM0.508789 5V7H18V5H0.508789ZM0.508789 10V12H18V10H0.508789Z'
          fill={`${color || 'currentColor'}`}
        />
      </svg>
    </StyledButton>
  );
};
