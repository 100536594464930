import styled from 'styled-components';

export const CssLogo = styled.div`
  padding-top: ${({ theme: { size } }) => size.spacing_5};
  padding-right: ${({ theme: { size } }) => size.spacing_2};
  padding-bottom: ${({ theme: { size } }) => size.spacing_5};
  padding-left: ${({ theme: { size } }) => size.spacing_6};
  & > svg {
    fill: ${(props) => props.theme.colors.bg.navbar.logo};
  }
  & .center {
    padding-top: 2px;
  }
`;
