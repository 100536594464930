import styled from 'styled-components';

export const StyledButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  width: 28px;
  height: 28px;
`;
