import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const CssItem = styled.li`
  float: left;
  cursor: pointer;
  &:hover:not(:has(.active)) {
    background-color: ${({ theme: { brandToken } }) =>
      brandToken.colors.gray_1};
  }
`;

export const CssNavLink = styled(NavLink)`
  display: block;
  padding: ${({ theme: { size } }) => size.spacing_5 + ' ' + size.spacing_6};
  color: ${({ theme: { brandToken } }) => brandToken.colors.bgray_8};
  text-align: center;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  > .caret {
    transform: rotate(180deg);
  }
  + ul {
    display: none;
  }

  &.active {
    background: #cfdbe8;
    // box-shadow: inset 0 -2px 0 #0e4d8d;
    color: ${({ theme: { brandToken } }) => brandToken.colors.apd_m};
    &:not(.hide-menu) {
      + ul {
        display: flex;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
      }
      > .caret {
        transform: rotate(0deg);
      }
    }
  }
`;

export const CssNavButton = styled.button`
  display: block;
  padding: ${({ theme: { size } }) => size.spacing_5 + ' ' + size.spacing_6};
  color: ${({ theme: { brandToken } }) => brandToken.colors.bgray_8};
  background-color: transparent;
  outline: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  > .caret {
    transform: rotate(180deg);
  }
  + ul {
    display: none;
  }

  &:not(.hide-menu) {
    background: #cfdbe8;
    color: ${({ theme: { brandToken } }) => brandToken.colors.apd_m};
    + ul {
      display: flex;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    }
    > .caret {
      transform: rotate(0deg);
    }
  }

  &.active {
    background: #cfdbe8;
    color: ${({ theme: { brandToken } }) => brandToken.colors.apd_m};
  }
`;

export const CssNavLinkSmall = styled(NavLink)`
  display: flex;
  padding: ${({ theme: { size } }) => size.spacing_2 + ' ' + size.spacing_3};
  color: ${({ theme: { brandToken } }) => brandToken.colors.gray_8};
  text-align: left;
  justify-content: space-between;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  > .caret {
    margin-left: auto;
    transform: rotate(180deg);
  }
  + ul {
    display: none;
  }

  &.active {
    background: #cfdde6;
    color: ${({ theme: { brandToken } }) => brandToken.colors.gray_8};
    &:not(.hide-sub-menu) {
      + ul {
        display: flex;
      }
      > .caret {
        transform: rotate(0deg);
      }
    }
  }
`;

export const CssNavButtonSmall = styled.button`
  display: flex;
  padding: ${({ theme: { size } }) => size.spacing_2 + ' ' + size.spacing_3};
  color: ${({ theme: { brandToken } }) => brandToken.colors.gray_8};
  background-color: transparent;
  outline: transparent;
  border: none;
  width: 100%;
  cursor: pointer;
  text-align: left;
  justify-content: space-between;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  > .caret {
    margin-left: auto;
    transform: rotate(180deg);
  }
  + ul {
    display: none;
  }

  &:not(.hide-sub-menu) {
    background: #cfdde6;
    color: ${({ theme: { brandToken } }) => brandToken.colors.gray_8};
    + ul {
      display: flex;
    }
    > .caret {
      transform: rotate(0deg);
    }
  }

  &.active {
    background: #cfdde6;
    color: ${({ theme: { brandToken } }) => brandToken.colors.gray_8};
  }
`;
