import React from 'react';
import { CssCaret } from './CaretSvg-style';

export function CaretSvg({ className }) {
  return (
    <CssCaret className={className}>
      <svg
        width='10'
        height='8'
        viewBox='0 0 10 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.5 6.375C8.99219 6.375 9.23828 5.80078 8.88281 5.44531L5.38281 1.94531C5.16406 1.72656 4.80859 1.72656 4.58984 1.94531L1.08984 5.44531C0.734375 5.80078 0.980469 6.375 1.47266 6.375H8.5Z'
          fill='currentColor'
        />
      </svg>
    </CssCaret>
  );
}
