import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CaretSvg } from '../CaretSvg/CaretSvg';

import {
  CssItem,
  CssNavButton,
  CssNavButtonSmall,
  CssNavLink,
  CssNavLinkSmall
} from './MenuItem-style';

const MenuItem = ({
  menu,
  index,
  hideMenu,
  toggleMenu,
  className = '',
  children,
  exact = true,
  main = true
}) => {
  const [hideSubMenu, setHideSubMenu] = useState(true);

  const { pathname } = useLocation();
  const handleToggle = (e) => {
    setHideSubMenu((state) => !state);
  };

  return (
    <CssItem key={index}>
      {main ? (
        !menu.items ? (
          <CssNavLink
            to={menu.route}
            exact={exact}
            activeClassName='active'
            className={'apd-nav-item ' + className}
            onClick={() => {
              if (toggleMenu) {
                toggleMenu();
              }
              setHideSubMenu(true);
            }}
            strict
          >
            {menu.name}
          </CssNavLink>
        ) : (
          <CssNavButton
            className={`apd-nav-item ${className} ${
              pathname.includes(menu.route) ? 'active' : ''
            }`}
            onClick={() => {
              if (toggleMenu) {
                toggleMenu();
              }
              setHideSubMenu(true);
            }}
          >
            {menu.name} {<CaretSvg className='caret' />}
          </CssNavButton>
        )
      ) : !menu.items ? (
        <CssNavLinkSmall
          to={menu.route}
          exact={exact}
          activeClassName='active'
          className={'apd-nav-item ' + (hideSubMenu ? 'hide-sub-menu' : '')}
          onClick={hideMenu}
        >
          {menu.name}
        </CssNavLinkSmall>
      ) : (
        <CssNavButtonSmall
          to={menu.route}
          exact={exact}
          className={`apd-nav-item ${hideSubMenu ? 'hide-sub-menu' : ''} ${
            pathname.includes(menu.route) ? 'active' : ''
          }`}
          onClick={handleToggle}
        >
          {menu.name} {<CaretSvg className='caret' />}
        </CssNavButtonSmall>
      )}
      {children}
    </CssItem>
  );
};

export default MenuItem;
