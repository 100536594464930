import React, { useState, useEffect, useRef, Fragment } from 'react';
import { MenuItem, LogoPorto, CloseButton, Hamburger } from './components';
import {
  CssNavBar,
  CssUl,
  NavbarWrapper,
  CssAppName,
  CssLinkWrapper,
  CssUlSmall,
  CssLinkWrapperColumn,
  ColumnTopBar,
  ColumnTitle,
  HamburgerWrapper,
  HamburgerChildrenWrapper,
  LogoLinksWrapper
} from './NavBar-style';
// eslint-disable-next-line camelcase
import { T_Body2 } from '@apd/typography';
import { useIsMobile } from './hooks';

const RenderMenuItems = ({ items, handleClose, column }) => {
  const [hideMenu, setHideMenu] = useState(true);
  const handleHide = () => {
    setHideMenu(true);
    if (column) handleClose();
  };
  const handleToggle = () => {
    setHideMenu((state) => !state);
  };
  useEffect(() => {
    window.onclick = (e) => {
      const drawer = document.querySelector('#apd-nav-drawer');
      if (!column && !e.target.classList.contains('apd-nav-item')) {
        handleHide();
      }
      const hideDrawerCondition =
        column &&
        !e.target.classList.contains('apd-nav-item') &&
        !e.target.classList.contains('apd-hamburger') &&
        drawer &&
        !drawer.querySelector(
          e.target.classList.toString()
            ? '.' + e.target.classList.toString().split(' ').join('.')
            : null
        ) &&
        e.target.classList.toString() !== drawer.classList.toString();
      if (hideDrawerCondition) {
        handleHide();
      }
    };
  }, []);
  return items.map((menu, index) => {
    if (!menu.name)
      console.error(
        'Menu JSON data [{name: string, route: string},{name: string, items: [{...}]}]'
      );
    if (!menu.items)
      return (
        <MenuItem
          key={menu.name + index}
          menu={menu}
          index={index}
          toggleMenu={handleHide} // When a link without sub links is clicked, hide the menu
        />
      );
    if (Array.isArray(menu.items) && menu.items.length) {
      return (
        <MenuItem
          key={menu.name + index}
          menu={menu}
          index={index}
          exact={false}
          className={hideMenu ? 'hide-menu' : ''}
          toggleMenu={handleToggle}
        >
          {!hideMenu ? (
            <DropdownMenuItem
              items={menu.items}
              index={index}
              hideMenu={handleHide}
              toggleMenu={handleToggle}
              className={hideMenu ? 'hide-menu' : ''}
            />
          ) : null}
        </MenuItem>
      );
    }
    return null;
  });
};

const DropdownMenuItem = ({
  items,
  index,
  hideMenu,
  toggleMenu,
  className
}) => {
  const content = () => {
    return items.map((menu, i) => {
      if (Array.isArray(menu.items) && menu.items.length) {
        return (
          <MenuItem
            key={i}
            main={false}
            menu={menu}
            index={index}
            exact={false}
            toggleMenu={toggleMenu}
            className={className}
          >
            <DropdownMenuItem
              hideMenu={hideMenu}
              items={menu.items}
              index={index}
              toggleMenu={toggleMenu}
              className={className}
            />
          </MenuItem>
        );
      }
      return (
        <MenuItem
          main={false}
          menu={menu}
          index={i}
          key={menu.name}
          hideMenu={hideMenu}
        />
      );
    });
  };
  return <CssUlSmall className='apd-inner-dropdown'>{content()}</CssUlSmall>;
};

const NavBar = ({ data, name, children }) => {
  const [menu, setMenu] = useState([]);
  const [drawerState, setDraweState] = useState('close');
  const [column, setColumn] = useState(false);
  const isMobile = useIsMobile();

  const navListRef = useRef(null);

  useEffect(() => {
    if (!data) return undefined;
    setMenu(data);
  }, [data]);

  const navListWrapper = navListRef.current;
  useEffect(() => {
    if (isMobile) {
      setColumn(true);
      return;
    }
    const navs = navListWrapper?.querySelector('ul').children;
    const totalLengthOfNavs = navs
      ? Array.from(navs).reduce((acc, curr) => {
          return acc + curr.clientWidth;
        }, 0)
      : 0;
    if (!navListWrapper) return;
    if (totalLengthOfNavs > navListWrapper.clientWidth) {
      setColumn(true);
    } else {
      setColumn(false);
    }
  }, [navListWrapper, isMobile]);

  const handleClose = () => setDraweState('close');
  const handleOpen = () => setDraweState('open');

  return (
    <NavbarWrapper>
      <CssNavBar>
        {column ? (
          <Fragment>
            <LogoPorto />
            <CssAppName>{name}</CssAppName>
            <CssLinkWrapperColumn id='apd-nav-drawer' className={drawerState}>
              <ColumnTopBar role='button' onClick={handleClose}>
                <CloseButton /> <ColumnTitle>Fechar</ColumnTitle>
              </ColumnTopBar>
              {/* eslint-disable-next-line react/jsx-pascal-case */}
              <T_Body2 className='menu-no-link'>Menu</T_Body2>
              <div className='inner-column-wrapper'>
                <CssUl column={column}>
                  <RenderMenuItems
                    items={menu}
                    handleClose={handleClose}
                    column={column}
                  />
                </CssUl>
              </div>
            </CssLinkWrapperColumn>
            <HamburgerChildrenWrapper>
              {children}
              <HamburgerWrapper>
                <Hamburger className='hamburger' onClick={handleOpen} />
              </HamburgerWrapper>
            </HamburgerChildrenWrapper>
          </Fragment>
        ) : (
          <Fragment>
            <LogoLinksWrapper>
              <LogoPorto />
              <CssAppName>{name}</CssAppName>
              <CssLinkWrapper ref={navListRef}>
                <CssUl>
                  <RenderMenuItems items={menu} />
                </CssUl>
              </CssLinkWrapper>
            </LogoLinksWrapper>
            {children}
          </Fragment>
        )}
      </CssNavBar>
    </NavbarWrapper>
  );
};

export default NavBar;
