import React from 'react';
import { StyledButton } from './CloseButton-style';

export const CloseButton = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <svg
        width='10'
        height='9'
        viewBox='0 0 10 9'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.42188 4.5L8.76562 2.15625C9.07031 1.875 9.07031 1.40625 8.76562 1.125L8.25 0.609375C7.96875 0.304688 7.5 0.304688 7.21875 0.609375L4.875 2.95312L2.50781 0.609375C2.22656 0.304688 1.75781 0.304688 1.47656 0.609375L0.960938 1.125C0.65625 1.40625 0.65625 1.875 0.960938 2.15625L3.30469 4.5L0.960938 6.86719C0.65625 7.14844 0.65625 7.61719 0.960938 7.89844L1.47656 8.41406C1.75781 8.71875 2.22656 8.71875 2.50781 8.41406L4.875 6.07031L7.21875 8.41406C7.5 8.71875 7.96875 8.71875 8.25 8.41406L8.76562 7.89844C9.07031 7.61719 9.07031 7.14844 8.76562 6.86719L6.42188 4.5Z'
          fill='black'
          fillOpacity='0.87'
        />
      </svg>
    </StyledButton>
  );
};
