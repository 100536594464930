import React from 'react';
import { CssLogo } from './LogoSvg-style';

const LogoPorto = () => (
  <CssLogo>
    <svg
      className='center'
      width='56'
      height='16'
      viewBox='0 0 56 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.72471 15.4492H0.968872V0.0673828H6.52731C10.3767 0.0673828 12.3299 2.44141 12.3299 5.34011C12.3299 8.23881 10.3767 10.7219 6.52731 10.7219H4.72471V15.4492ZM4.72471 3.34011V7.4492H5.77926C7.31693 7.4492 8.45978 6.91933 8.45978 5.34011C8.45978 3.78167 7.31693 3.34011 5.77926 3.34011H4.72471Z'
        fill='current'
      />
      <path
        d='M12.3247 10.3273C12.3247 7.20527 14.7611 4.96631 17.8156 4.96631C20.8702 4.96631 23.3065 7.21046 23.3065 10.3273C23.3065 13.4494 20.8702 15.7092 17.8156 15.7092C14.7663 15.7144 12.3247 13.4494 12.3247 10.3273ZM15.8416 10.3273C15.8416 11.5793 16.652 12.5247 17.8208 12.5247C18.9637 12.5247 19.8 11.5793 19.8 10.3273C19.8 9.05462 18.9637 8.15072 17.8208 8.15072C16.652 8.15072 15.8416 9.05462 15.8416 10.3273Z'
        fill='current'
      />
      <path
        d='M30.9845 8.14028H29.7118C28.1949 8.14028 27.8001 9.45457 27.8001 10.7481V15.4494H24.2832V5.23119H27.665V6.85716C27.878 5.69353 28.6261 5.19482 29.3066 5.19482C29.5715 5.19482 30.9897 5.23119 30.9897 5.23119V8.14028H30.9845Z'
        fill='current'
      />
      <path
        d='M37.0572 1.58412V5.23087H39.104V8.1088H37.0572V11.8231C37.0572 12.4153 37.5611 12.5036 37.9611 12.5036C38.6208 12.5036 39.104 12.4361 39.104 12.4361V15.4439C39.104 15.4439 37.4572 15.5737 36.5949 15.5737C34.9481 15.5737 33.5403 15.1114 33.5403 13.0023V8.1088H32.0234V5.22049H33.5403V1.57373H37.0572V1.58412Z'
        fill='current'
      />
      <path
        d='M39.8468 10.3273C39.8468 7.20527 42.2832 4.96631 45.3377 4.96631C48.3922 4.96631 50.8286 7.21046 50.8286 10.3273C50.8286 13.4494 48.3871 15.7092 45.3377 15.7092C42.2832 15.7144 39.8468 13.4494 39.8468 10.3273ZM43.3585 10.3273C43.3585 11.5793 44.1689 12.5247 45.3377 12.5247C46.4806 12.5247 47.3169 11.5793 47.3169 10.3273C47.3169 9.05462 46.4806 8.15072 45.3377 8.15072C44.1741 8.15072 43.3585 9.05462 43.3585 10.3273Z'
        fill='current'
      />
      <path
        d='M53.6027 11.2983C54.8338 11.2983 55.8209 12.2854 55.8209 13.5165C55.8209 14.7269 54.8338 15.7139 53.6027 15.7139C52.3975 15.7139 51.4053 14.7269 51.4053 13.5165C51.4053 12.2854 52.3923 11.2983 53.6027 11.2983Z'
        fill='current'
      />
    </svg>
  </CssLogo>
);

export default LogoPorto;
