import styled from 'styled-components';

export const CssNavBar = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: fill-available;
  height: ${({ theme: { size } }) => size.layout_5};
  background: ${(props) => props.theme.colors.bg.navbar.default};
  display: flex;
  z-index: ${(props) => props.theme.zIndex.house};
  // box-shadow: inset 0 -2px 0 ${(props) =>
    props.theme.colors.border.navbar.default};
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.24));
  font-family: Roboto;
`;

export const CssUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: ${({ column }) => (column ? 'flex' : 'block')};
  flex-direction: ${({ column }) => (column ? 'column' : 'unset')};
`;

export const CssUlSmall = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  > li > a {
    border-radius: 2px;
  }
`;

export const CssLogo = styled.div`
  padding-top: ${({ theme: { size } }) => size.spacing_5};
  padding-right: ${({ theme: { size } }) => size.spacing_2};
  padding-bottom: ${({ theme: { size } }) => size.spacing_5};
  padding-left: ${({ theme: { size } }) => size.spacing_6};
  & > svg {
    fill: ${(props) => props.theme.colors.bg.navbar.logo};
  }
  & .center {
    padding-top: 2px;
  }
`;

export const CssAppName = styled.div`
  float: left;
  display: block;
  padding-top: ${({ theme: { size } }) => size.spacing_5};
  padding-right: ${({ theme: { size } }) => size.spacing_6};
  padding-bottom: ${({ theme: { size } }) => size.spacing_5};
  padding-left: ${({ theme: { size } }) => size.spacing_2};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.bg.navbar.logo};
`;
export const CssLinkWrapper = styled.div``;
export const CssLinkWrapperColumn = styled.div`
  width: 250px;
  height: 100vh;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: 0;
  transition: right 0.4s;
  background-color: white;
  > .inner-column-wrapper {
    padding: 0 8px;
  }
  a.apd-nav-item,
  button.apd-nav-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 4px 8px;
  }
  a.apd-nav-item {
    text-align: left;
  }
  button.apd-nav-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  ul.apd-inner-dropdown {
    box-shadow: none !important;
  }
  li:not(.inner-dropdown, :has(button.apd-nav-item)) {
    margin-bottom: 4px;
  }
  .apd-inner-dropdown {
    padding-bottom: 0px;
    padding-top: 4px;
  }
  div.menu-no-link {
    color: ${({ theme }) => theme.brandToken.colors.gray_6};
    padding-left: ${({ theme }) => theme.size.spacing_5};
  }
  &.close {
    right: -300px;
  }
  &.open {
    right: 0px;
  }
`;
export const NavbarWrapper = styled.div``;
export const ColumnTopBar = styled.div`
  cursor: pointer;
  padding: 12px 8px;
  border-bottom: solid 1px ${({ theme }) => theme.brandToken.colors.gray_3};
  margin-bottom: ${({ theme }) => theme.size.spacing_3};
`;

export const ColumnTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: ${({ theme }) => theme.text.size.body2};
  line-height: ${({ theme }) => theme.text.line_height.body2};
`;

export const HamburgerWrapper = styled.div`
  margin-left: auto;
  display: flex;
  .hamburger {
    color: ${({ theme }) => theme.brandToken.colors.apd_m};
    cursor: pointer;
    padding-right: 24px;
  }
`;

export const HamburgerChildrenWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const LogoLinksWrapper = styled.div`
  display: flex;
  margin-right: auto;
`;
